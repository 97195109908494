<template>
  <div class="container">
    <nav class="navbar package-navbar-custom level px-6">
      <!-- Left side -->
      <div class="level-left">
        <div class="level-item">
          <a href="/subscription">
            <b-icon icon="chevron-left" />
          </a>
        </div>
        <div class="level-item">
          <h2 class="package-detail-nav-title">
            {{
              this.$route.query.mode === 'payNow'
                ? 'Data Invoice'
                : 'Choose Package'
            }}
          </h2>
        </div>
      </div>
    </nav>
    <PaymentDetail :data="datas" :mode="this.$route.query.mode"></PaymentDetail>
  </div>
</template>

<script>
import axios from 'axios'
import PaymentDetail from '../Payment/components/PaymentDetail.vue'

export default {
  components: { PaymentDetail },
  data() {
    return {
      datas: null,
    }
  },
  async mounted() {
    await this.getPackageDetail()
  },
  methods: {
    async getPackageDetail() {
      let packageId = this.$route.query.id
      let mode = this.$route.query.mode
      try {
        if (mode == 'choose') {
          const response = await axios.post(
            '/api/space-roketin/payment/v1/package/subscribe',
            { package_id: packageId }
          )
          this.datas = response.data.data
        } else if (mode == 'payNow') {
          const response = await axios.post(
            '/api/space-roketin/payment/v1/package/resubscribe',
            null
          )
          this.datas = response.data.data
        } else {
          const response = await axios.post(
            '/api/space-roketin/payment/v1/package/subscribe/prorate',
            { package_id: packageId }
          )
          this.datas = response.data.data
        }
      } catch (error) {
        console.error(error)
      }
    },

    async getResubscribeDetail() {},
  },
}
</script>
