<template>
  <div>
    <div v-if="!data" class="columns is-centered mt-6">
      <div class="column is-narrow">
        <div class="loader is-loading">
          <div class="bounce1"></div>
          <div class="bounce2"></div>
          <div class="bounce3"></div>
        </div>
      </div>
    </div>

    <div v-else class="container payment-detail-page">
      <div class="columns px-6">
        <div class="column is-6 px-6">
          <h4 class="title-summary">Order Summary</h4>
          <span class="subscribe">Subscribe to Rise</span>
          <h1 class="is-price">
            {{ convertToCurrency(data.items[0].price_package) }}
            <span class="is-month">/month</span>
          </h1>
          <p class="package-desc">
            This package fit to your business with
            <span class="user-capacity">
              {{ data.items[0].max_user }} Max Users
            </span>
          </p>
        </div>
        <div class="column is-6 px-5 py-5 is-right-card">
          <div class="is-flex">
            <img class="is-icon-right" src="/images/rise-icon.png" alt="" />
            <div class="mt-1 ml-2">
              <h5>Company Packages</h5>
              <p class="text-bill">Billed monthly</p>
            </div>
          </div>

          <div class="card-right">
            <a
              v-if="!isPromo"
              class="add-promotion"
              @click.prevent="addPromo()"
            >
              Add promotion code
            </a>
            <b-field v-else>
              <b-input
                placeholder="Add Promo"
                expanded
                v-model="voucherCode"
                :disabled="voucherPercentage"
              />
              <button
                id="VoucherApply"
                class="voucher-btn"
                @click="onClickApplyVoucher()"
                v-if="!voucherPercentage"
              >
                apply
              </button>
              <b-icon
                icon="close"
                class="pointer-click voucher-close"
                v-else
                @click.native="deleteVoucher()"
              />
            </b-field>
            <div class="is-flex is-justify-content-space-between header-card">
              <div>
                <p class="left-text mb-2">Total</p>
                <p class="left-text mb-2">
                  Discount {{ data.items[0].discount_amount }}%
                </p>
                <p class="left-text mb-2" v-if="voucherPercentage">
                  Voucher {{ voucherPercentage }}%
                </p>
                <p class="left-text">Tax {{ data.items[0].tax_percentage }}%</p>
              </div>
              <div>
                <p class="right-text mb-1">
                  {{ convertToCurrency(data.items[0].price_package) }}
                </p>
                <p class="right-text mb-1">
                  -{{ convertToCurrency(data.items[0].show_discount) }}
                </p>
                <p class="right-text mb-1" v-if="voucherPercentage">
                  -{{ convertToCurrency(voucherAmount) }}
                </p>
                <p class="right-text">+ {{ convertToCurrency(countTax()) }}</p>
              </div>
            </div>

            <div class="is-flex is-justify-content-space-between mt-4">
              <div>
                <p class="left-text mb-4">Grand Total</p>
              </div>
              <div>
                <p class="right-text mb-4">
                  {{ convertToCurrency(countSubtotal()) }}
                </p>
              </div>
            </div>

            <div class="buttons">
              {{ data.items[0].code }}
              <b-button
                :loading="isLoading"
                @click.native="goToPayment(data.code)"
                type="is-primary"
                expanded
              >
                Proceed Payment
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import { showToast } from '../../../services/util'
import ApiService from '@/services/common/api.service'
export default {
  props: {
    data: {
      default: null,
      type: Object,
    },
    mode: {
      default: '',
      type: String,
    },
  },
  data() {
    return {
      isPromo: false,
      isLoading: false,
      voucherCode: '',
      voucherPercentage: null,
      voucherAmount: 0,
      subtotalAmount: 0,
      tax: 0,
      discountedPrice: 0,
      priceAfterVoucher: 0,
    }
  },
  async mounted() {
    await this.countTax()
    await this.countSubtotal()
  },
  methods: {
    ...mapActions({
      actionLoadVoucher: 'subscription/validateVoucher',
    }),
    ...mapMutations({
      setCurrentVoucher: 'subscription/setCurrentVoucher',
    }),
    async goToPayment(code) {
      this.isLoading = true
      try {
        if (this.mode == 'payNow') {
          let form = new FormData()
          form.append('order_id', code)
          form.append('company_name', this.data.user.company_name)

          if (this.voucherPercentage) {
            form.append('voucher_code', this.voucherCode)
          }
          const paymentReq = await ApiService.post(
            '/api/space-roketin/payment/v1/payment/send/resubscribe',
            form,
            true
          )

          if (paymentReq.status == 200) {
            window.location.href = `${process.env.VUE_APP_PAYMENT_URL}checkout?order=${code}`
          }
        } else if (this.mode == 'choose' || this.mode == 'upgrade') {
          let form = new FormData()
          form.append('order_id', code)
          form.append('company_name', this.data.user.company_name)

          if (this.voucherPercentage) {
            form.append('voucher_code', this.voucherCode)
          }
          const paymentReq = await ApiService.post(
            '/api/space-roketin/payment/v1/payment/send',
            form,
            true
          )

          if (paymentReq.status == 200) {
            window.location.href = `${process.env.VUE_APP_PAYMENT_URL}checkout?order=${code}`
          }
        } else {
          const response = await ApiService.post('/api/space-roketin/company', {
            name: localStorage.getItem('name'),
          })
          if (response.status == 201) {
            let form = new FormData()
            form.append('order_id', code)
            form.append('company_name', localStorage.getItem('name'))

            if (this.voucherPercentage) {
              form.append('voucher_code', this.voucherCode)
            }
            const paymentReq = await ApiService.post(
              '/api/space-roketin/payment/v1/payment/send',
              form,
              true
            )

            if (paymentReq.status == 200) {
              window.location.href = `${process.env.VUE_APP_PAYMENT_URL}checkout?order=${code}`

              localStorage.removeItem('name')
            }
          }
        }
      } catch (error) {
        const errorMessage = JSON.parse(error.response.data.meta.message)
        showToast(errorMessage.name, 'is-danger', 'is-top')
      }

      this.isLoading = false
    },
    addPromo() {
      this.isPromo = !this.isPromo
    },

    async onClickApplyVoucher() {
      var button = document.getElementById('VoucherApply')
      try {
        let response = await this.actionLoadVoucher(this.voucherCode)
        if (response && response.status == 200) {
          this.voucherPercentage = response.data.data.discount

          this.voucherAmount = Math.ceil(
            (this.data.items[0].price_package -
              this.data.items[0].show_discount) *
              (this.voucherPercentage / 100)
          )
        }
        button.style.cursor = 'not-allowed'
      } catch (error) {
        console.log(error)
        showToast(error.response.data.message, 'is-danger', 'is-top')
      }
    },

    countTax() {
      this.discountedPrice =
        this.data?.items[0]?.price_package - this.data?.items[0]?.show_discount
      this.priceAfterVoucher =
        this.discountedPrice - (this.voucherAmount ? this.voucherAmount : 0)
      this.tax =
        this.priceAfterVoucher * (this.data?.items[0]?.tax_percentage / 100)

      return this.tax
    },

    countSubtotal() {
      this.subtotalAmount = Math.ceil(
        this.data?.items[0]?.price_package -
          this.data?.items[0]?.show_discount -
          (this.voucherAmount ? this.voucherAmount : 0) +
          this.tax
      )

      return this.subtotalAmount
    },

    deleteVoucher() {
      var button = document.getElementById('VoucherApply')
      this.voucherCode = null
      this.voucherPercentage = null
      this.voucherAmount = 0
      button.style.cursor = 'pointer'
    },

    convertToCurrency(amount) {
      const formatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
        minimumFractionDigits: 0,
      })
      return formatter.format(amount)
    },
  },
}
</script>

<style></style>
